
  import { defineComponent,ref,onMounted,getCurrentInstance} from 'vue'
  import { useRouter,useRoute } from 'vue-router'
  import storage from '@/assets/js/localStorage'
import { Toast, Dialog } from 'vant'
import api  from '@/assets/js/api'
  export default defineComponent({
    name: '',
    props: {
        isPay:{
            type:Boolean,
            default: false
        },
        orderInfo: {
            type: Object,
            default: {
                totalAmountRmb: 0,
                goodsTotal: 0
            }
        },
        noticeCheck:{
            type: Array,
            default: []
        },
        fromType: {
            type:String,
            default: 'normal'
        }
    },
    components: {
        [Toast.name]: Toast,
         [Dialog.name]: Dialog,
    },
    setup: (props,{emit}) => {
        
         const {proxy:_this} = getCurrentInstance()
        const {orderInfo} = props
        const router = useRouter()
        const route = useRoute()
        const ua = window.navigator.userAgent.toLowerCase();
        const isIOS = !ua.includes("android");
    //    专门为了兼容 页面被返回，重新渲染问题
      
        onMounted(()=>{
        if(storage.getPayDetailBack()){
                    storage.setPayDetailBack(0)
                    Dialog.alert({
                                message: '查看支付结果',
                                closeOnClickOverlay: false,
                                }).then(() => {
                                router.push({name: 'paystatus',params:{id:storage.getTradeNo()}})
                                })
                }
        })
        const payHandle =(_this as any).debounce(async ()=>{
           if((props as any).fromType === 'normal' &&  (props as any).noticeCheck?.length < orderInfo.buyerReading?.length){
                Toast('请先勾选底部协议！')
                window.scrollTo(0,50000);
           }else{
              let {data:detailInfo} = await api.goodsDetailApi({goods_id:storage.getGoodsParams().id ,site: storage.getGoodsParams().site})
              console.log(storage.getOrderParams())
                if(detailInfo.status <= 0 && storage.getOrderParams()?.formType !== 'unpaid'){
                    Toast('此商品已售罄！');
                    return 
                }
            try {
                 let resp:any = {}
                if(props.fromType === 'normal'){
                    storage.setOrderInfo(orderInfo)
                        // 结算页下单
                    let data = {formType: orderInfo.formType,payType: 'alipay', settlementSign:orderInfo.settlementSign,goods_id:orderInfo.settlementGoods[0].goods[0].id,itemIds: orderInfo.payItemIds}
                    resp =  await api.payApi(data)     
                } else {
                    // 订单详情下单直接下单
                    //  const {data} =  await api.orderSaveApi({formType: 'unpaid',itemIds:route.params.id})
                    //  resp =  await api.payApi({formType: data.formType,payType: 'alipay', settlementSign:data.settlementSign,itemIds:route.params.id})   
                    // storage.setOrderParams({formType: 'unpaid',itemIds:route.params.id,payItemIds: pageData.orderInfo.itemIds})
                    // router.push('/shopping/paydetail')

                    // 跳转结算页下单
                    router.push('/shopping/paydetail')
                } 
                const showToast = ()=>{
                        Dialog.alert({
                        message: '查看支付结果',
                        closeOnClickOverlay: false,
                        }).then(() => {
                        router.push({name: 'paystatus',params:{id:resp.data.tradeNo}})
                        })
                } 
                storage.setTradeNo(resp.data.tradeNo)
                 storage.setPayDetailBack(1)
            //  支付以后自动跳转逻辑，暂时取消
            if(isIOS){
                window.addEventListener('pageshow', (event)=> {
                    // (window as any).location.href = `${window.location.origin}/shopping/paystatus/${storage.getTradeNo()}`
                //    showToast()
                }) 
            } else {
                document.addEventListener('visibilitychange', (e:any)=>{
                 
                //  showToast()
                // router.push({name: 'paystatus',params:{id:resp.data.tradeNo}})
                

            })
            }
            //  showToast() 
                if (document.getElementById('alipay') !== null) {
                    const alipayDiv = document.getElementById('alipay')
                    alipayDiv.parentNode.removeChild(alipayDiv)
                }
                const div = document.createElement('div')
                div.id = 'alipay'
                div.style['visibility']='hidden'
                div.innerHTML = (resp as any).data.alipay.sign_str
                document.body.appendChild(div)
                document.forms[0].acceptCharset = (resp as any).data.alipay.sign_str.indexOf('UTF-8') !== -1 ? 'UTF-8' : 'GBK' // 保持与支付宝默认编码格式一致，如果不一致将会出现：调试错误，请回到请求来源地，重新发起请求，错误代码 invalid-signature 错误原因: 验签出错，建议检查签名字符串或签名私钥与应用公钥是否匹配
               setTimeout(() => {
                   
                    showToast() 
                   
                }, 1000);  
               document.forms[0].submit()
                if(div){
                    document.removeChild(div)
                }
                  
            } catch (err) {
                if(err=='无效参数'){
                    router.push({name: 'paystatus',params:{id:storage.getTradeNo()}})
                } else{
                  if(err.length){
                    Toast(err)
                  }
                    
                }
             
            }
          
           }
        })
        return {
            payHandle,
            isIOS
        }
    }
  })
