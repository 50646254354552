import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ccd679c2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "goods-header" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "goods-content" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "goods-info" }
const _hoisted_6 = { class: "goods-info-top" }
const _hoisted_7 = { class: "goods-name" }
const _hoisted_8 = { class: "goods-num" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_icon = _resolveComponent("van-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: "card-detail-item card-item-goods",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toDetailHandle && _ctx.toDetailHandle(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("img", {
        src: _ctx.goodsInfo.site_logo,
        alt: ""
      }, null, 8, _hoisted_2),
      _createTextVNode(" " + _toDisplayString(_ctx.goodsInfo.siteName), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("img", {
        src: _ctx.goodsInfo.goods[0].image,
        alt: ""
      }, null, 8, _hoisted_4),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.goodsInfo.goods[0].name), 1),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.goodsInfo.goods[0].tag, (item) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "goods-tag",
              key: item.name
            }, _toDisplayString(item.name), 1))
          }), 128))
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("span", null, _toDisplayString(_ctx.globalAmountRule(_ctx.goodsInfo.goods[0].goodsOrderTotalPriceYen)) + "日元 (约 " + _toDisplayString(_ctx.globalAmountRule(_ctx.goodsInfo.goods[0].goodsOrderTotalPriceRmb)) + " 元)", 1),
          _createElementVNode("div", null, [
            _createVNode(_component_van_icon, { name: "cross" }),
            _createElementVNode("p", null, _toDisplayString(_ctx.goodsInfo.goods[0].buyQuantity), 1)
          ])
        ])
      ])
    ])
  ]))
}