
  import { defineComponent} from 'vue'
  import { Icon} from 'vant'
  import { useRouter } from 'vue-router'
  import storage from '@/assets/js/localStorage'
  export default defineComponent({
    name: '',
    props: {
        goodsInfo:{
            type: Object,
            default:{
                goods:[{
                    image:'',
                    name: '',
                    buyQuantity: '',
                    goodsOrderTotalPriceYen: '',
                    goodsOrderTotalPriceRmb: ''
                    }]
            }
        }
    },
    components: {
        [Icon.name]: Icon
    },
    setup: (props) => {
        const router = useRouter()
        const toDetailHandle = ()=>{
            router.push(`/shopping/detail/${storage.getGoodsParams().site}/${storage.getGoodsParams().id}`)
        }
        return {
            toDetailHandle
        }
    }
  })
