
  import { defineComponent,ref,reactive,toRefs} from 'vue'
  import HeaderVue from '@/components/shopping/Header.vue'
  import { Icon, Radio, RadioGroup, Checkbox, CheckboxGroup,Toast,Dialog} from 'vant'
  import PayFooter from '@/components/shopping/PayFooter.vue'
  import { useRouter,useRoute } from 'vue-router'
  import { useStore, mapGetters, mapMutations } from 'vuex'
  import GoodsCardVue from '@/components/shopping/GoodsCard.vue'
  import api from '@/assets/js/api'
  import storage from '@/assets/js/localStorage'
  export default defineComponent({
    name: 'paydetail',
    props: {},
    components: {
        HeaderVue,
        [Icon.name]: Icon,
         [RadioGroup.name]: RadioGroup,
        [Radio.name]: Radio,
         [Checkbox.name]: Checkbox,
        [CheckboxGroup.name]: CheckboxGroup,
        [Toast.name]: Toast,
        [Dialog.name]: Dialog,
        GoodsCardVue,
        PayFooter,
    },
    setup: () => {
        const checked = ref('1')
          const checkedT = ref([])
          const store = useStore()
          const route:any = useRoute()
          const router = useRouter()

          const pageData:any = reactive({
            orderParams:  storage.getOrderParams(),
            orderInfo: {
                settlementGoods:[{}]
            }
          })
          const getOrderInfoHandle =async () => {
           try {
             let res = await api.orderSaveApi(pageData.orderParams)
            //  若来自未支付订单，这页面信息从缓存获取，接口返的不全
             if(pageData.orderParams.formType.includes('unpaid')){
                pageData.orderInfo = storage.getOrderInfo()
                pageData.orderInfo.formType = res.data.formType
                pageData.orderInfo.settlementSign = res.data.settlementSign
                 pageData.orderInfo.payItemIds = pageData.orderParams.payItemIds
             } else {
                // 正常下单，数据来源接口
                    pageData.orderInfo = res.data
             }
            pageData.orderInfo.paymentMethod.map(item=>{
                    if(item.checkout) {
                        checked.value = item.id            
                    }
                })
           
           } catch (err){
             if(err.length){
                if(err==='无效参数'){
                    Toast('请获取正确的商品链接')
                } else if(err.includes('已售出/缺货中')) {
                    //  Toast(err)
                    // 针对safiri 部分浏览器，会重新获取该接口信息，实际上接口商品已经被售出，而导致页面无法继续运行而弹出的弹窗
                    Dialog.alert({
                        message: '查看支付结果',
                        closeOnClickOverlay: false,
                        }).then(() => {
                        router.push({name: 'paystatus',params:{id:storage.getTradeNo()}})
                        })
                }
               
                
             }
            if(route.query.form){
                router.push(route.query.form)
            } else{
                // router.go(-1)
            }
               
               
           }
          }
          getOrderInfoHandle()
        return{
           checked,
           checkedT,
           ...toRefs(pageData)
        }
    }
  })
